<script>
import httpClient from "@/services/http.service";

export default {
  name: "ListCertificateHoldersWinterScienceSchool",
  data(){
    return{
      certificateHoldersList: []
    }
  },
  methods:{
    async getCertificateHoldersList() {
      try {
        const {status, data} = await httpClient.get('api/certificate-holders/get-qr')
        if (status === 200) {
          this.certificateHoldersList = data
        }
      } catch (e) {
        this.$error({title: "Ошибка", details: e})
      }
    },
  },
  async mounted() {
    await this.getCertificateHoldersList()
  }
}
</script>

<template>
  <div class="container" v-if="certificateHoldersList.length > 0">
    <h4 class="text-center my-3">Список обладателей сертификатов</h4>
    <DataTable :value="certificateHoldersList" tableStyle="min-width: 50rem" paginator :rows="10"
               paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
               :rowsPerPageOptions="[10, 20, 50]" currentPageReportTemplate="Показано {first}-{last} из {totalRecords}">
      <Column header="№">
        <template #body="{index}">
          {{index+1}}
        </template>
      </Column>
      <Column field="name" header="Name"></Column>
      <Column header="Quantity">
        <template #body="{data}">
          <img width="100" height="100" :src="`https://back.uib.kz/${data.qr}`" alt="QR-а нет">
        </template>
      </Column>
    </DataTable>
  </div>
  <h4 v-else class="text-center my-3">Список сертификатов пуст</h4>
</template>

<style scoped>

</style>